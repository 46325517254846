<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        md4
      >
        <material-card
          color="blue darken-4"
          title="XPRT"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="valid && submit($event)"
          >
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="User Name"
                    color="blue darken-4"
                    required
                  />
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :type="'password'"
                    name="input-10-1"
                    label="Password"
                    color="blue darken-4"
                    required
                  />
                </v-flex>

                <v-flex
                  xs12
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="blue darken-4"
                    @click="submit"
                  >
                    <span class="tw-font-bold tw-text-white">Login</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="error"
      bottom
      absolute
      auto-height
      color="red"
    >
      {{ error_message }}
      <v-btn
        text
        dark
        class="red"
        @click="error = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      valid: true,
      error: false,
      error_message: '',
      password: '',
      passwordRules: [
        v => !!v || 'Please enter your password'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Please enter your user'
      ]
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        })
          .then(response => {
            this.$router.push({ name: 'Leads' })
          })
          .catch(error => {
            this.error = true
            this.error_message = error
          })
      }
    }
  }
}
</script>
<style>
  .v-card--material__header .title{
    font-size: 22px !important;
    font-weight: 500 !important;
  }
</style>
